import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Col, Typography, Select } from 'antd';
import { usStates } from '../../data/common-data';

import chevron from '../../images/svg/chevron_left.svg';

const { Title } = Typography;
const { Option } = Select;

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

const LPLeadForm = (props) => {
  const {
    leadType,
    header,
    subHeader,
    formId,
    phoneNumber,
    phoneText,
    thankYouPath,
  } = props;
  const form = useRef(null);
  const [loading, setLoading] = useState(false);
  const [textMessages, setTextMessages] = useState(false);
  const emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const phoneValidation = new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  );

  const onFinish = (values) => {
    setLoading(true);
    const formState = {
      Restaurant_Name: values.Restaurant_Name,
      Contact_Name: values.Contact_Name,
      Phone: values.Phone,
      Restaurant_Website: values.Restaurant_Website,
      Email: values.Email,
      State: values.State,
      City: values.City,
      Zipcode: values.Zipcode,
      PFG_AE_Name: values.PFG_AE_Name,
      PFG_AE_Email: values.PFG_AE_Email,
      PFG_AE_Phone_Number: values.PFG_AE_Phone_Number,
    };
    fetch(`/`, {
      method: `POST`,
      body: encode({
        'form-name': `PFG Leads`,
        ...formState,
      }),
    })
      .then(() => {
        if (window.sessionStorage.getItem('Name')) {
          window.sessionStorage.removeItem('Name', values.Contact_Name);
          window.sessionStorage.setItem('Name', values.Contact_Name);
        } else {
          window.sessionStorage.setItem('Name', values.Contact_Name);
        }
        if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
          // eslint-disable-next-line no-undef
          dataLayer.push({ event: `${leadType}-lead` });
        }
      })
      .then(() => {
        setLoading(false);
        window.location.pathname = thankYouPath;
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.errors[0].errorType.toString() ===
            'INVALID_EMAIL'
          ) {
            // eslint-disable-next-line no-alert
            return alert(
              `Looks like the email ${values.email} can not be validated.
                Please use a valid email.`
            );
          }
        } else if (error.request) {
          // eslint-disable-next-line no-console
          console.log(error.request);
        } else {
          // eslint-disable-next-line no-console
          console.log('Error', error.message);
        }
      });
  };
  return (
    <Col xs={23} md={22} lg={20}>
      <section
        className="lead-form-wrapper"
        ref={form}
        style={{ marginBottom: 30 }}
      >
        <form
          name="PFG Leads"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          hidden
        >
          <input type="text" name="Restaurant_Name" />
          <input type="text" name="Contact_Name" />
          <input type="text" name="Phone" />
          <input type="text" name="Restaurant_Website" />
          <input type="email" name="Email" />
          <input type="text" name="City" />
          <input type="text" name="State" />
          <input type="text" name="Zipcode" />
          <input type="text" name="PFG_AE_Name" />
          <input type="text" name="PFG_AE_Email" />
          <input type="text" name="PFG_AE_Phone_Number" />
        </form>
        <Row className="header-form-wrapper">
          <Col xs={24}>
            <Title level={1} style={{ textAlign: 'center' }}>
              {header}
              <span className="blue_dot">.</span>
            </Title>
          </Col>
          <Col xs={24}>
            <p style={{ fontSize: 16 }}>
              {subHeader}
              <a style={{ fontSize: 16, fontWeight: 800 }} href={phoneNumber}>
                {' '}
                {phoneText}
              </a>
            </p>
          </Col>
        </Row>

        <Form
          name="PFG Leads"
          className="lead-form in-lp"
          layout="vertical"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          {/* Name */}
          <input type="hidden" name="Rasi Form" value="contact" />
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Restaurant Name/Group "
                name="Restaurant_Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the restaurant name',
                    min: 2,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. Johnny's Wings Shack" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Owner/Operator Full Name"
                name="Contact_Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a name',
                    min: 2,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. John Doe" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* Email */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Owner/Operator Email Address"
                name="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid email',
                    pattern: emailValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. john@company.com" />
              </Form.Item>
            </Col>
            {/* Phone */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Owner/Operator Phone Number"
                name="Phone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid phone',
                    pattern: phoneValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. (888) 224-6506" />
              </Form.Item>
            </Col>
          </Row>
          {/* Business Name */}
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Restaurant Website"
                name="Restaurant_Website"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the restaurant website',
                    min: 3,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. www.businessname.com " />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="City"
                name="City"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a city',
                    min: 3,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. San Francisco" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="PFG Account Executive Full Name"
                name="PFG_AE_Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a name',
                    min: 2,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. Jane Doe" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <p className="label-business-type">State</p>
              <Form.Item
                style={{ overflow: 'auto' }}
                name="State"
                rules={[
                  {
                    required: true,
                    message: 'Please select a state',
                  },
                ]}
              >
                <Select
                  placeholder="CA"
                  style={{ width: '100%', color: 'black !important' }}
                >
                  {usStates.map(({ abbreviation }) => (
                    <Option
                      key={abbreviation}
                      value={abbreviation}
                      className="drop-down__small"
                    >
                      {abbreviation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <p className="label-business-type">Zipcode</p>
              <Form.Item
                name="Zipcode"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid zipcode',
                    len: 5,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. 94104" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* Email */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="PFG Account Executive Email"
                name="PFG_AE_Email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid email',
                    pattern: emailValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. jane@company.com" />
              </Form.Item>
            </Col>
            {/* Phone */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="PFG Account Executive Phone"
                name="PFG_AE_Phone_Number"
                rules={[
                  {
                    required: true,
                    message: 'Please prove a valid phone number',
                    pattern: phoneValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. (888) 224-6506" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="cta-primary"
                style={{ marginTop: 20 }}
                loading={loading}
                size="small"
              >
                Submit Lead
                <img
                  src={chevron}
                  alt="chevron icon"
                  style={{ margin: '5px 5px 2px 10px' }}
                />
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </section>
    </Col>
  );
};

LPLeadForm.propTypes = {
  formId: PropTypes.string.isRequired,
  leadType: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  phoneText: PropTypes.string,
  thankYouPath: PropTypes.string,
};
LPLeadForm.defaultProps = {
  phoneNumber: '',
  phoneText: '',
  thankYouPath: '/thank-you',
};

export default LPLeadForm;
