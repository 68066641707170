import React from "react";
import { Row, Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import PFGForm from "../../components/CustomForms/PFGform";
import Badges from "../../components/ConversionPath/Badges";

import ogImage from "../../images/global_assets/og-image.png";
import logoPFG from "../../images/pfg.png";
import logoSpotOn from "../../images/svg/SpotOnLogo.svg";

export default function PFGPage() {
  const { Paragraph } = Typography;
  return (
    <Layout isLanding phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="PFG + SpotOn| Register a Lead"
        description="PFG + SpotOn| Register a Lead"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        {/* <div className="demo__content-wrapper"> */}
        <Row
          justify="center"
          align="middle"
          style={{ fontSize: 42, marginTop: 40 }}
        >
          <img src={logoPFG} style={{ maxWidth: 200, marginRight: 20 }} />{" "}
          <span style={{ marginBottom: 10 }}>+</span>{" "}
          <img src={logoSpotOn} style={{ marginLeft: 20 }} />
        </Row>
        <Row justify="center" align="middle">
          <Paragraph
            className="normal-paragraph"
            style={{ textAlign: "center", padding: 20 }}
          >
            Please use this form to share information about restaurants that
            would benefit from joining the SpotOn platform.
          </Paragraph>
        </Row>
        <Row
          justify="center"
          align="middle"
          style={{ maxWidth: "95%", margin: "auto" }}
        >
          <PFGForm
            header="Register a Lead"
            leadType="pfg"
            formId="091bb640-0b33-4a08-97b4-f787b4ddb362"
            thankYouPath="/partners/thank-you"
          />
        </Row>
        <Badges />
      </section>
    </Layout>
  );
}
